import Head from 'next/head'
import Layout from '../components/Layout.js'
import HomePage from '../components/Home'
import styles from '../styles/Home.module.css'

const SCRIPTS_TARGET = 'production'

const renderGoogleHeadTags = () => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === SCRIPTS_TARGET) {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K5QKZCS');
          `
        }}
      />
    )
  }
}

const renderGoogleBodyTags = () => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === SCRIPTS_TARGET) {
    return (
      <noscript id="it_gtm">
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-K5QKZCS"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    )
  }
}

export default function Home() {
  return (
    <Layout>
      <Head>
        <title>Merry Modz Advent Calendar</title>
        <meta name="description" content="Merry Modz Advent Calendar" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content="Merry Modz Advent Calendar" />
        <meta property="og:url" content="https://advent.merrymodz.io" />
        {/* <meta property="og:image" content="https://impacttheory.com/wp-content/uploads/2018/11/impact-theory-logo.png" /> */}

        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3201730,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
        
        {renderGoogleHeadTags()}
        
      </Head>

      <main className={styles.main}>
        {renderGoogleBodyTags()}
        <HomePage />
      </main>
    </Layout>
  )
}
