import Footer from "./Footer";
import styles from '../styles/Home.module.css'
import ITNavBar from "./navbar/ITNavBar";

export default function Layout({ children }) {
    return (
        <div className={styles.container}>

            {/* <ITNavBar /> */}

            <main className={styles.main}>
                {children}
            </main>

            {/* <Footer /> */}
        </div>

    )
}